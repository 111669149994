
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Mlogin from '@/mixins/login';
import apps from '@/views/apps.js'
@Component({
  components: {
    dingtalk: () => import('./components/dingtalk'),
    passwd: () => import('./components/passwd'),
    sms: () => import('./components/sms'),
    wechat: () => import('./components/wechat'),
    wxwork: () => import('./components/wxwork'),
  },
})
export default class PcLogin extends Vue {
  get currentTabComponent() {
    let s = 'dingtalk';
    s = (this as any).channels;
   
    return s;
  }
}
